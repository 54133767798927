import React, { useRef } from "react";
import { motion } from "framer-motion";
import Header from "../header";
import SubHeader from "../sub-header";
import { MenuToggle } from "../menu-toggle";
import { useAppState } from "../../hooks/use-appstate";
import { useDimensions } from "../../hooks/use-dimensions";
import { Menu } from "./menu";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/home");
  };

  const containerRef = useRef();

  const { menuOpen } = useAppState();

  const { height } = useDimensions(containerRef);

  const menu = {
    open: (h = 1000) => ({
      clipPath: `circle(${h * 2 + 200}px at 40px 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: "circle(0px at 40px 40px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <>
      <header className="flex justify-between items-center h-[12%] overflow-hidden p-5">
        <div className="flex flex-col">
          <button className={"aligntextleft"} onClick={goHome}>
            <Header className="text-3xl">Discover</Header>
            <SubHeader className="text-black text-lg leading-[0.8]">
              Lanzarote Your Way
            </SubHeader>
          </button>
        </div>
        <MenuToggle />
      </header>
      <motion.div
        initial={false}
        animate={menuOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
      >
        <motion.div
          className="bg-white absolute top-0 bottom-0 left-0 w-full h-full p-5 z-[21] overflow-y-auto"
          variants={menu}
        >
          <Menu />
        </motion.div>
      </motion.div>
    </>
  );
};

export default Navbar;
