import { useCallback, useEffect, useState } from "react";

export const useWindowDimensions = ref => {

    const [dimensions, setDimensions] = useState({width: 0, height:0, offsetX: 0});

    const getDimensions = useCallback(() => {
      const page = document.getElementById("page-wrapper")
        return {
            width: page?.clientWidth,
            height: page?.clientHeight,
            offsetX: page?.getBoundingClientRect().left
        };
    }, [ref])

    useEffect(() => {
        setDimensions(getDimensions());
    }, [ref]);

    return dimensions;
};
