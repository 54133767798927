import React, { forwardRef, useState } from "react";
import MuxPlayer from "@mux/mux-player-react";
import { Player } from "@lottiefiles/react-lottie-player";
import { cn } from "../utils";

import loadingLottie from "../assets/plane-loading.json";

const Video = forwardRef(
  (
    { playbackId, location, className, hideLoader, isFeatured, ...props },
    ref
  ) => {
    const [loaded, setLoaded] = useState(false);
    return (
      <>
        {!loaded && !hideLoader && (
          <div className="flex inset-0 justify-center items-center absolute z-[500]">
            <Player
              src={loadingLottie}
              loop={true}
              autoplay={true}
              className="h-24 w-24"
            ></Player>
          </div>
        )}
        {!isFeatured && <span className="location-pin">{location}</span>}
        <MuxPlayer
          ref={ref}
          className={cn("h-full w-full", className)}
          streamType="on-demand"
          playbackId={playbackId}
          thumbnailTime={0.01}
          maxResolution="1080p"
          primaryColor="#FFFFFF"
          secondaryColor="#000000"
          playsInline=""
          onCanPlay={() => setLoaded(true)}
          preload="metadata"
          muted={false}
          autoPlay={false}
          {...props}
        />
      </>
    );
  }
);

export default Video;
