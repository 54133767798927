import React, { useEffect } from "react";
import Navbar from "../components/layout/navbar";
import Footer from "../components/layout/footer";
import BookNow from "../components/book-now";
import { useAppState } from "../hooks/use-appstate";

const ThankYou = () => {
  const { iframeOpen, setIframeOpen, fullscreen, setFullscreen } =
    useAppState();

  useEffect(() => {
    if (iframeOpen) {
      setIframeOpen(false);
    }
    if (fullscreen) {
      setFullscreen(null);
    }
  }, []);

  return (
    <div className="relative w-full h-full overflow-hidden">
      <Navbar />
      <div className="relative h-[66%] px-5 z-[1]">
        <div className="flex flex-col space-y-2 rounded-lg h-full w-full p-4 pb-4 orangeborder">
          <h1 className="thanks">You're in, good luck!</h1>
          <svg
            width="auto"
            height="auto"
            viewBox="0 0 547 547"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M273.5 34.1875C336.97 34.1875 397.84 59.4007 442.719 104.281C487.599 149.16 512.812 210.03 512.812 273.5C512.812 336.97 487.599 397.84 442.719 442.719C397.84 487.599 336.97 512.812 273.5 512.812C210.03 512.812 149.16 487.599 104.281 442.719C59.4007 397.84 34.1875 336.97 34.1875 273.5C34.1875 210.03 59.4007 149.16 104.281 104.281C149.16 59.4007 210.03 34.1875 273.5 34.1875ZM243.688 320.713L190.527 267.517C188.621 265.611 186.359 264.1 183.869 263.068C181.378 262.037 178.71 261.506 176.014 261.506C173.319 261.506 170.65 262.037 168.16 263.068C165.67 264.1 163.408 265.611 161.502 267.517C157.653 271.366 155.49 276.587 155.49 282.03C155.49 287.473 157.653 292.693 161.502 296.542L229.193 364.234C231.093 366.149 233.354 367.669 235.845 368.707C238.336 369.744 241.007 370.278 243.706 370.278C246.404 370.278 249.075 369.744 251.566 368.707C254.057 367.669 256.318 366.149 258.218 364.234L398.387 224.031C400.318 222.133 401.855 219.871 402.908 217.377C403.96 214.882 404.509 212.203 404.522 209.496C404.534 206.788 404.011 204.105 402.981 201.6C401.951 199.096 400.436 196.82 398.522 194.904C396.609 192.989 394.335 191.471 391.832 190.438C389.329 189.405 386.646 188.879 383.938 188.888C381.23 188.897 378.551 189.443 376.055 190.493C373.559 191.543 371.296 193.076 369.396 195.005L243.688 320.713Z"
              fill="#FF7700"
            />
          </svg>
          <h2 className="eager">Eager to visit?</h2>
          <BookNow />
        </div>
      </div>
      <Footer text={"Watch more videos"} />
    </div>
  );
};

export default ThankYou;
