import bgBeaches from "./assets/images/categories/beaches.png";
import bgExperience from "./assets/images/categories/adventure.png";
import bgNature from "./assets/images/categories/nature.png";
import bgFood from "./assets/images/categories/food.png";
import bgHotel from "./assets/images/categories/hotel.png";
import bgBeachesSq from "./assets/images/categories/beachessq.png";
import bgExperienceSq from "./assets/images/categories/adventuresq.png";
import bgNatureSq from "./assets/images/categories/naturesq.png";
import bgFoodSq from "./assets/images/categories/foodsq.png";
import bgHotelSq from "./assets/images/categories/hotelsq.png";
import defaultThumbnail from "./assets/images/thumbnails/thumbnail.jpg";
import instagram from "./assets/images/social icons/insta.svg";
import tiktok from "./assets/images/social icons/tiktok.svg";
import facebook from "./assets/images/social icons/facebook.svg";
import youtube from "./assets/images/social icons/youtube.svg";
import x from "./assets/images/social icons/x.svg";

/*

You can generate gifs using this URL
https://image.mux.com/{playbackId}/animated.gif?time=20

Competition end date is ISO 8601 format
*/

export const config = {
  competitionEndDate: "2024-08-14T00:00:00Z",
  social: [
    {
      name: "Instagram",
      icon: instagram,
      url: "https://www.instagram.com/easyjetholidays",
    },
    {
      name: "YouTube",
      icon: youtube,
      url: "https://youtube.com/@easyjetholidays",
    },
    {
      name: "Facebook",
      icon: facebook,
      url: "https://www.facebook.com/easyjetholidays/",
    },
    {
      name: "TikTok",
      icon: tiktok,
      url: "https://tiktok.com/@easyjetholidays",
    },
    {
      name: "X",
      icon: x,
      url: "https://x.com/easyJetholidays",
    },
  ],
  defaultThumbnail: defaultThumbnail,
  heroVideo: {
    playbackId: "krDMEzjQkKx02xF9roLVd7txItMXopyiIZ8sTbJX3Goo",
    title:
      "Lanzarote unveiled: Your easyJet holidays escape to volcanic wonders!",
    description:
      "Dive into Lanzarote's unique charm with easyJet holidays. Explore the surreal beauty of Timanfaya National Park, relax on golden beaches, and discover enchanting towns with mouthwatering local cuisine. Lanzarote is a sun-kissed escape filled with unforgettable moments at every turn.",
  },
  categories: [
    {
      id: "beaches",
      bg: bgBeaches,
      bgsq: bgBeachesSq,
      title: "Beaches",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="currentColor"
        >
          <path
            d="M17.4835 18.317L11.1335 11.967L12.5335 10.567L18.8835 16.917L17.4835 18.317ZM3.83354 17.617C2.83354 16.617 2.09188 15.492 1.60854 14.242C1.12521 12.992 0.883545 11.717 0.883545 10.417C0.883545 9.11699 1.12521 7.85033 1.60854 6.61699C2.09188 5.38366 2.83354 4.26699 3.83354 3.26699C4.83354 2.26699 5.95438 1.52116 7.19604 1.02949C8.43771 0.537826 9.70854 0.291992 11.0085 0.291992C12.3085 0.291992 13.5794 0.537826 14.821 1.02949C16.0627 1.52116 17.1835 2.26699 18.1835 3.26699L3.83354 17.617ZM4.03355 14.567L5.38354 13.217C5.11688 12.867 4.86271 12.5087 4.62104 12.142C4.37938 11.7753 4.15855 11.4087 3.95854 11.042C3.75854 10.6753 3.58354 10.3087 3.43354 9.94199C3.28355 9.57533 3.15021 9.21699 3.03355 8.86699C2.85021 9.85033 2.83771 10.8337 2.99604 11.817C3.15438 12.8003 3.50021 13.717 4.03355 14.567ZM6.83355 11.817L12.3835 6.21699C11.6669 5.66699 10.946 5.22116 10.221 4.87949C9.49605 4.53783 8.81688 4.30449 8.18355 4.17949C7.55021 4.05449 6.97938 4.03366 6.47105 4.11699C5.96271 4.20033 5.56688 4.38366 5.28355 4.66699C5.00021 4.96699 4.81688 5.37116 4.73355 5.87949C4.65021 6.38783 4.67105 6.96283 4.79605 7.60449C4.92104 8.24616 5.15438 8.92533 5.49605 9.64199C5.83771 10.3587 6.28355 11.0837 6.83355 11.817ZM13.7835 4.81699L15.1835 3.46699C14.3002 2.93366 13.3669 2.58366 12.3835 2.41699C11.4002 2.25033 10.4169 2.26699 9.43355 2.46699C9.80021 2.58366 10.1669 2.71699 10.5335 2.86699C10.9002 3.01699 11.2669 3.18783 11.6335 3.37949C12.0002 3.57116 12.3627 3.78783 12.721 4.02949C13.0794 4.27116 13.4335 4.53366 13.7835 4.81699Z"
            fill="currentColor"
          />
        </svg>
      ),
      smallIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 19 19"
          fill="currentColor"
        >
          <path
            d="M17.4835 18.317L11.1335 11.967L12.5335 10.567L18.8835 16.917L17.4835 18.317ZM3.83354 17.617C2.83354 16.617 2.09188 15.492 1.60854 14.242C1.12521 12.992 0.883545 11.717 0.883545 10.417C0.883545 9.11699 1.12521 7.85033 1.60854 6.61699C2.09188 5.38366 2.83354 4.26699 3.83354 3.26699C4.83354 2.26699 5.95438 1.52116 7.19604 1.02949C8.43771 0.537826 9.70854 0.291992 11.0085 0.291992C12.3085 0.291992 13.5794 0.537826 14.821 1.02949C16.0627 1.52116 17.1835 2.26699 18.1835 3.26699L3.83354 17.617ZM4.03355 14.567L5.38354 13.217C5.11688 12.867 4.86271 12.5087 4.62104 12.142C4.37938 11.7753 4.15855 11.4087 3.95854 11.042C3.75854 10.6753 3.58354 10.3087 3.43354 9.94199C3.28355 9.57533 3.15021 9.21699 3.03355 8.86699C2.85021 9.85033 2.83771 10.8337 2.99604 11.817C3.15438 12.8003 3.50021 13.717 4.03355 14.567ZM6.83355 11.817L12.3835 6.21699C11.6669 5.66699 10.946 5.22116 10.221 4.87949C9.49605 4.53783 8.81688 4.30449 8.18355 4.17949C7.55021 4.05449 6.97938 4.03366 6.47105 4.11699C5.96271 4.20033 5.56688 4.38366 5.28355 4.66699C5.00021 4.96699 4.81688 5.37116 4.73355 5.87949C4.65021 6.38783 4.67105 6.96283 4.79605 7.60449C4.92104 8.24616 5.15438 8.92533 5.49605 9.64199C5.83771 10.3587 6.28355 11.0837 6.83355 11.817ZM13.7835 4.81699L15.1835 3.46699C14.3002 2.93366 13.3669 2.58366 12.3835 2.41699C11.4002 2.25033 10.4169 2.26699 9.43355 2.46699C9.80021 2.58366 10.1669 2.71699 10.5335 2.86699C10.9002 3.01699 11.2669 3.18783 11.6335 3.37949C12.0002 3.57116 12.3627 3.78783 12.721 4.02949C13.0794 4.27116 13.4335 4.53366 13.7835 4.81699Z"
            fill="currentColor"
          />
        </svg>
      ),
      videos: [
        {
          playbackId: "7yoB00lOX1RkdWqxVpCgQHa02WvAMwTrHr2302xHamaOIw",
          location: "Playa de Famara",
          title: "Title Text",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
        },
        {
          playbackId: "etKAr02Rzgt99vPs7QSmqqhxq00FEgrZSlSxDs2aIl98U",
          title: "Title Text 2",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Playa Caletón Blanco",
        },
        {
          playbackId: "Xzwl4D001XKKJYnn7ND3X2Y02Q4PBB6S3P01GHrdvyDzgo",
          title: "Title Text 3",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Playa de Papagayo",
        },
        {
          playbackId: "OchGVG16daovE02I3uEliBrCswr02nAjloDD8AWLKpOHk",
          title: "Title Text 4",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Punta Mujeres & Casa Carmelina",
        },
        {
          playbackId: "e2Xa01C3gxaRueEmXxP1GC6wffvQI3I02PtyT2tzTzWgU",
          title: "Title Text 5",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Playa Chica",
        },
        {
          playbackId: "clWkb1mfFjFqptmX5Rzs00VPcGc01y02qh3ESYvdPJ02xf00",
          title: "Title Text 6",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Playa de la Cantería",
        },
        {
          playbackId: "fB00NwdJbDqr7AHZDRvC01vxZwsgdPeJ3buapTyTjWM0100",
          title: "Title Text 7",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Charco de los Clicos",
        },
        {
          playbackId: "cmFSeAUC9s9dq4qXQS7Aj7HaSLgGtAVjhWfAUTj8ugo",
          title: "Title Text 8",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Playa de Papagayo",
        },
        {
          playbackId: "xr8Ads1aKdC1azrWwaehnHKoSNR8uiT02A2Cnzn6pNYU",
          title: "Title Text 9",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Playa Caletón Blanco",
        },
      ],
    },
    {
      id: "experience",
      bg: bgExperience,
      bgsq: bgExperienceSq,
      title: "Experience",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="currentColor"
        >
          <path
            d="M7.38352 23.3353L10.4585 7.78533C10.5585 7.30199 10.7835 6.93949 11.1335 6.69783C11.4835 6.45616 11.8502 6.33533 12.2335 6.33533C12.6169 6.33533 12.971 6.41866 13.296 6.58533C13.621 6.75199 13.8835 7.00199 14.0835 7.33533L15.0835 8.93533C15.3835 9.41866 15.771 9.85616 16.246 10.2478C16.721 10.6395 17.2669 10.927 17.8835 11.1103V9.33533H19.3835V23.3353H17.8835V13.1853C17.0835 13.002 16.3419 12.7103 15.6585 12.3103C14.9752 11.9103 14.3835 11.4187 13.8835 10.8353L13.2835 13.8353L15.3835 15.8353V23.3353H13.3835V17.3353L11.2835 15.3353L9.48352 23.3353H7.38352ZM7.80852 13.4603L5.68352 13.0603C5.41685 13.0103 5.20852 12.8728 5.05852 12.6478C4.90852 12.4228 4.85852 12.1687 4.90852 11.8853L5.65852 7.96033C5.75852 7.42699 6.04185 7.00616 6.50852 6.69783C6.97519 6.38949 7.47519 6.28533 8.00852 6.38533L9.15852 6.61033L7.80852 13.4603ZM13.8835 5.83533C13.3335 5.83533 12.8627 5.63949 12.471 5.24783C12.0794 4.85616 11.8835 4.38533 11.8835 3.83533C11.8835 3.28533 12.0794 2.81449 12.471 2.42283C12.8627 2.03116 13.3335 1.83533 13.8835 1.83533C14.4335 1.83533 14.9044 2.03116 15.296 2.42283C15.6877 2.81449 15.8835 3.28533 15.8835 3.83533C15.8835 4.38533 15.6877 4.85616 15.296 5.24783C14.9044 5.63949 14.4335 5.83533 13.8835 5.83533Z"
            fill="currentColor"
          />
        </svg>
      ),
      smallIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 25 25"
          fill="currentColor"
        >
          <path
            d="M7.38352 23.3353L10.4585 7.78533C10.5585 7.30199 10.7835 6.93949 11.1335 6.69783C11.4835 6.45616 11.8502 6.33533 12.2335 6.33533C12.6169 6.33533 12.971 6.41866 13.296 6.58533C13.621 6.75199 13.8835 7.00199 14.0835 7.33533L15.0835 8.93533C15.3835 9.41866 15.771 9.85616 16.246 10.2478C16.721 10.6395 17.2669 10.927 17.8835 11.1103V9.33533H19.3835V23.3353H17.8835V13.1853C17.0835 13.002 16.3419 12.7103 15.6585 12.3103C14.9752 11.9103 14.3835 11.4187 13.8835 10.8353L13.2835 13.8353L15.3835 15.8353V23.3353H13.3835V17.3353L11.2835 15.3353L9.48352 23.3353H7.38352ZM7.80852 13.4603L5.68352 13.0603C5.41685 13.0103 5.20852 12.8728 5.05852 12.6478C4.90852 12.4228 4.85852 12.1687 4.90852 11.8853L5.65852 7.96033C5.75852 7.42699 6.04185 7.00616 6.50852 6.69783C6.97519 6.38949 7.47519 6.28533 8.00852 6.38533L9.15852 6.61033L7.80852 13.4603ZM13.8835 5.83533C13.3335 5.83533 12.8627 5.63949 12.471 5.24783C12.0794 4.85616 11.8835 4.38533 11.8835 3.83533C11.8835 3.28533 12.0794 2.81449 12.471 2.42283C12.8627 2.03116 13.3335 1.83533 13.8835 1.83533C14.4335 1.83533 14.9044 2.03116 15.296 2.42283C15.6877 2.81449 15.8835 3.28533 15.8835 3.83533C15.8835 4.38533 15.6877 4.85616 15.296 5.24783C14.9044 5.63949 14.4335 5.83533 13.8835 5.83533Z"
            fill="currentColor"
          />
        </svg>
      ),
      videos: [
        {
          playbackId: "MvoqiXYpbGTEbT2V02dvt01r01AfV01W6oXdTUfHDPuBrJU",
          title: "Title Text",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "La Geria & Bodegas Rubicón",
        },
        {
          playbackId: "yznFLQcyh101f178KhCHV02RvMCNmLloDri34ouXN9f44",
          title: "Title Text 2",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Jameos del Agua",
        },
        {
          playbackId: "TRyXtsxZrrO00MH5aq4dvtAyNz7m7dDRxccbbB9OxGK00",
          title: "Title Text 3",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Timanfaya National Park",
        },
        {
          playbackId: "NHTcj02FWjTnSxxqcey5Sqlwq7oksBqjaTVxEfaXiQdU",
          title: "Title Text 4",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Charco de los Clicos",
        },
        {
          playbackId: "Z01SEFYb3y9OUELMdk9ZME010181vIZ59DFlDHuDgIC7MY",
          title: "Title Text 5",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Mirador del Rio",
        },
        {
          playbackId: "401nwzxYDfeuQjkoiX5FqAA7SErycMiLIVSN5Jo9016UY",
          title: "Title Text 6",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Teguise",
        },
      ],
    },
    {
      id: "nature",
      bg: bgNature,
      bgsq: bgNatureSq,
      title: "Nature",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="currentColor"
        >
          <g clipPath="url(#clip0_0_2683)">
            <path d="M7.38354 22.3538V18.3538H0.383545L4.23355 12.3538H2.38354L9.38354 2.35376L12.3835 6.65376L15.3835 2.35376L22.3835 12.3538H20.5335L24.3835 18.3538H17.3835V22.3538H13.3835V18.3538H11.3835V22.3538H7.38354ZM17.1085 16.3538H20.7335L16.8585 10.3538H18.5335L15.3835 5.85376L13.6085 8.37876L16.3835 12.3538H14.5335L17.1085 16.3538ZM4.03355 16.3538H14.7335L10.8585 10.3538H12.5335L9.38354 5.85376L6.23354 10.3538H7.90855L4.03355 16.3538ZM4.03355 16.3538H7.90855H6.23354H12.5335H10.8585H14.7335H4.03355ZM17.1085 16.3538H14.5335H16.3835H13.6085H18.5335H16.8585H20.7335H17.1085Z" />
          </g>
          <defs>
            <clipPath id="clip0_0_2683">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.383545 0.35376)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      smallIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 25 25"
          fill="currentColor"
        >
          <g clipPath="url(#clip0_0_2683)">
            <path d="M7.38354 22.3538V18.3538H0.383545L4.23355 12.3538H2.38354L9.38354 2.35376L12.3835 6.65376L15.3835 2.35376L22.3835 12.3538H20.5335L24.3835 18.3538H17.3835V22.3538H13.3835V18.3538H11.3835V22.3538H7.38354ZM17.1085 16.3538H20.7335L16.8585 10.3538H18.5335L15.3835 5.85376L13.6085 8.37876L16.3835 12.3538H14.5335L17.1085 16.3538ZM4.03355 16.3538H14.7335L10.8585 10.3538H12.5335L9.38354 5.85376L6.23354 10.3538H7.90855L4.03355 16.3538ZM4.03355 16.3538H7.90855H6.23354H12.5335H10.8585H14.7335H4.03355ZM17.1085 16.3538H14.5335H16.3835H13.6085H18.5335H16.8585H20.7335H17.1085Z" />
          </g>
          <defs>
            <clipPath id="clip0_0_2683">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.383545 0.35376)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      videos: [
        {
          playbackId: "2XqWMfOAJw5Z6WRTivDiMPG5FhAGu8uBZMhbndz7Sn4",
          title: "Title Text",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Timanfaya National Park",
        },
        {
          playbackId: "mBbex4IS02b016aXAdUCQlxLXWL8nRds546kpz00iyQmOI",
          title: "Title Text 2",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Playa Caletón Blanco",
        },
        {
          playbackId: "tESHC5U2o01fGg8fR9LqVmMvyC9OqWyK9HZI02sa02RVpE",
          title: "Title Text 3",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Charco de los Clicos",
        },
        {
          playbackId: "vWQ02Bl00s5uMr02tEOHmBNbLcJoJAmpsP00CIimtCyY7Rc",
          title: "Title Text 4",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Playa de Famara",
        },
        {
          playbackId: "DNqt02lU5sLzKTCKijMfHwOa4Tnfj6QK00zEDYCU1JVfc",
          title: "Title Text 5",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "El Golfo",
        },
        {
          playbackId: "15QdqxEbePKwezle00MtlxbJA5PBLqz00Q025IvqSuuhQ8",
          title: "Title Text 6",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "El Golfo",
        },
      ],
    },
    {
      id: "food",
      bg: bgFood,
      bgsq: bgFoodSq,
      title: "Food & Drink",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="currentColor"
        >
          <path
            d="M13.7085 13.3711C13.1752 12.6211 12.471 12.1003 11.596 11.8086C10.721 11.5169 9.81688 11.3711 8.88354 11.3711C7.95021 11.3711 7.04604 11.5169 6.17104 11.8086C5.29605 12.1003 4.59188 12.6211 4.05854 13.3711H13.7085ZM1.38354 15.3711C1.38354 13.5544 2.14188 12.1003 3.65855 11.0086C5.17521 9.91693 6.91688 9.37109 8.88354 9.37109C10.8502 9.37109 12.5919 9.91693 14.1085 11.0086C15.6252 12.1003 16.3835 13.5544 16.3835 15.3711H1.38354ZM1.38354 19.3711V17.3711H16.3835V19.3711H1.38354ZM18.3835 23.3711V21.3711H19.7835L21.1835 7.37109H11.6335L11.3835 5.37109H16.3835V1.37109H18.3835V5.37109H23.3835L21.7335 21.9211C21.6835 22.3378 21.5002 22.6836 21.1835 22.9586C20.8669 23.2336 20.5002 23.3711 20.0835 23.3711H18.3835ZM2.38354 23.3711C2.10021 23.3711 1.86271 23.2753 1.67104 23.0836C1.47938 22.8919 1.38354 22.6544 1.38354 22.3711V21.3711H16.3835V22.3711C16.3835 22.6544 16.2877 22.8919 16.096 23.0836C15.9044 23.2753 15.6669 23.3711 15.3835 23.3711H2.38354Z"
            fill="currentColor"
          />
        </svg>
      ),
      smallIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 25 25"
          fill="currentColor"
        >
          <path
            d="M13.7085 13.3711C13.1752 12.6211 12.471 12.1003 11.596 11.8086C10.721 11.5169 9.81688 11.3711 8.88354 11.3711C7.95021 11.3711 7.04604 11.5169 6.17104 11.8086C5.29605 12.1003 4.59188 12.6211 4.05854 13.3711H13.7085ZM1.38354 15.3711C1.38354 13.5544 2.14188 12.1003 3.65855 11.0086C5.17521 9.91693 6.91688 9.37109 8.88354 9.37109C10.8502 9.37109 12.5919 9.91693 14.1085 11.0086C15.6252 12.1003 16.3835 13.5544 16.3835 15.3711H1.38354ZM1.38354 19.3711V17.3711H16.3835V19.3711H1.38354ZM18.3835 23.3711V21.3711H19.7835L21.1835 7.37109H11.6335L11.3835 5.37109H16.3835V1.37109H18.3835V5.37109H23.3835L21.7335 21.9211C21.6835 22.3378 21.5002 22.6836 21.1835 22.9586C20.8669 23.2336 20.5002 23.3711 20.0835 23.3711H18.3835ZM2.38354 23.3711C2.10021 23.3711 1.86271 23.2753 1.67104 23.0836C1.47938 22.8919 1.38354 22.6544 1.38354 22.3711V21.3711H16.3835V22.3711C16.3835 22.6544 16.2877 22.8919 16.096 23.0836C15.9044 23.2753 15.6669 23.3711 15.3835 23.3711H2.38354Z"
            fill="currentColor"
          />
        </svg>
      ),
      videos: [
        {
          playbackId: "Fg5x00E01rSwTmME7pj7YcST7jPXjo372EjGawl3luwus",
          title: "Title Text",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Casa Torano, El Golfo",
        },
        {
          playbackId: "pbpIMmzl3A9pZH36bBQOiCh029DySspENqsKMdNKir8U",
          title: "Title Text 2",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "La Ola, Puerto del Carmen",
        },
        {
          playbackId: "TVkMLJT2xQACk4CG02OHwuvsYT7Ov0000pSqz01LhYilFl4",
          title: "Title Text 3",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "La Nonna, Elba Lanzarote Royal",
        },
        {
          playbackId: "UrV2YiWYVpn8rbxW2Odv7CaKjuxDgZfVGxH001qM402kE",
          title: "Title Text 4",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Restaurante La Nasa, Arrieta",
        },
        {
          playbackId: "jEtlNSBFl9ph8l2601tr9jWZ5F2IIvEPW02VN2TgAdBL8",
          title: "Title Text 5",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "D'amore Café, Teguise",
        },
      ],
    },
    {
      id: "hotel",
      bg: bgHotel,
      bgsq: bgHotelSq,
      title: "Hotel Life",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="currentColor"
        >
          <path
            d="M1.88354 19.3892V4.38916H3.88354V14.3892H11.8835V6.38916H19.8835C20.9835 6.38916 21.9252 6.78083 22.7085 7.56416C23.4919 8.34749 23.8835 9.28916 23.8835 10.3892V19.3892H21.8835V16.3892H3.88354V19.3892H1.88354ZM7.88354 13.3892C7.05021 13.3892 6.34188 13.0975 5.75854 12.5142C5.17521 11.9308 4.88354 11.2225 4.88354 10.3892C4.88354 9.55583 5.17521 8.84749 5.75854 8.26416C6.34188 7.68083 7.05021 7.38916 7.88354 7.38916C8.71688 7.38916 9.42521 7.68083 10.0085 8.26416C10.5919 8.84749 10.8835 9.55583 10.8835 10.3892C10.8835 11.2225 10.5919 11.9308 10.0085 12.5142C9.42521 13.0975 8.71688 13.3892 7.88354 13.3892ZM13.8835 14.3892H21.8835V10.3892C21.8835 9.83916 21.6877 9.36833 21.296 8.97666C20.9044 8.58499 20.4335 8.38916 19.8835 8.38916H13.8835V14.3892ZM7.88354 11.3892C8.16688 11.3892 8.40438 11.2933 8.59605 11.1017C8.78771 10.91 8.88354 10.6725 8.88354 10.3892C8.88354 10.1058 8.78771 9.86833 8.59605 9.67666C8.40438 9.48499 8.16688 9.38916 7.88354 9.38916C7.60021 9.38916 7.36271 9.48499 7.17104 9.67666C6.97938 9.86833 6.88354 10.1058 6.88354 10.3892C6.88354 10.6725 6.97938 10.91 7.17104 11.1017C7.36271 11.2933 7.60021 11.3892 7.88354 11.3892Z"
            fill="currentColor"
          />
        </svg>
      ),
      smallIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 25 25"
          fill="currentColor"
        >
          <path
            d="M1.88354 19.3892V4.38916H3.88354V14.3892H11.8835V6.38916H19.8835C20.9835 6.38916 21.9252 6.78083 22.7085 7.56416C23.4919 8.34749 23.8835 9.28916 23.8835 10.3892V19.3892H21.8835V16.3892H3.88354V19.3892H1.88354ZM7.88354 13.3892C7.05021 13.3892 6.34188 13.0975 5.75854 12.5142C5.17521 11.9308 4.88354 11.2225 4.88354 10.3892C4.88354 9.55583 5.17521 8.84749 5.75854 8.26416C6.34188 7.68083 7.05021 7.38916 7.88354 7.38916C8.71688 7.38916 9.42521 7.68083 10.0085 8.26416C10.5919 8.84749 10.8835 9.55583 10.8835 10.3892C10.8835 11.2225 10.5919 11.9308 10.0085 12.5142C9.42521 13.0975 8.71688 13.3892 7.88354 13.3892ZM13.8835 14.3892H21.8835V10.3892C21.8835 9.83916 21.6877 9.36833 21.296 8.97666C20.9044 8.58499 20.4335 8.38916 19.8835 8.38916H13.8835V14.3892ZM7.88354 11.3892C8.16688 11.3892 8.40438 11.2933 8.59605 11.1017C8.78771 10.91 8.88354 10.6725 8.88354 10.3892C8.88354 10.1058 8.78771 9.86833 8.59605 9.67666C8.40438 9.48499 8.16688 9.38916 7.88354 9.38916C7.60021 9.38916 7.36271 9.48499 7.17104 9.67666C6.97938 9.86833 6.88354 10.1058 6.88354 10.3892C6.88354 10.6725 6.97938 10.91 7.17104 11.1017C7.36271 11.2933 7.60021 11.3892 7.88354 11.3892Z"
            fill="currentColor"
          />
        </svg>
      ),
      videos: [
        {
          playbackId: "yVQFt581Sx01Qe5IvaCwn00IYm011Oae00uf600caGUrc9xg",
          title: "Title Text",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Elba Lanzarote Royal Village Resort",
        },
        {
          playbackId: "sbXzsovj00faQmxMD1WEhcn9w5HzTtqxHu684DxRMnUk",
          title: "Title Text 2",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Elba Lanzarote Royal Village Resort",
        },
        {
          playbackId: "8MBGmvT2CHFtCD005ychgYgVozy9uCH4t3t35s8oIGds",
          title: "Title Text 3",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Elba Lanzarote Royal Village Resort",
        },
        {
          playbackId: "KqPNlqKbpXnUsHqLCeatCkg7kwwtoh02SRpyCYKtY5xE",
          title: "Title Text 4",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Elba Lanzarote Royal Village Resort",
        },
        {
          playbackId: "8DSAKucvC6IOX00z1BZQZcGTwmiJaf300iahSEBMKTCzw",
          title: "Title Text 5",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Elba Lanzarote Royal Village Resort",
        },
        {
          playbackId: "koUvNYqOXpa5L1zyUxb02Mthz7G02UxNkHgx7LRo01odD00",
          title: "Title Text 6",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Elba Lanzarote Royal Village Resort",
        },
        {
          playbackId: "MQ028UaGdYymIiheZP003WSOZ02uLEtWdLGhGOC2BlXhMU",
          title: "Title Text 7",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Elba Lanzarote Royal Village Resort",
        },
        {
          playbackId: "H5HLCW31ENQOpy4kRHqWqLQ3eNiSyseqKvHO009XzVcc",
          title: "Title Text 8",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Elba Lanzarote Royal Village Resort",
        },
        {
          playbackId: "xjQghZDd6qOpYeJGsFR6Qw9fba6HZJ02K7Z3keWvm9n4",
          title: "Title Text 9",
          thumbnailTime: "1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do....",
          location: "Elba Lanzarote Royal Village Resort",
        },
      ],
    },
  ],
};
