import React from 'react';
import Header from '../components/header';

const ErrorScreen = () => {
    return (
        <div className='flex flex-col bg-ej-dark items-center justify-center space-y-3'>
            <Header>Error!</Header>
            <p>Sorry, something went wrong.</p>
        </div>
    );
};

export default ErrorScreen;