import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Video from "../video";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useAppState } from "../../hooks/use-appstate";
import { trackEvent } from "../../analytics";

const VideoSwiper = ({ swiper, setSwiper, nextVideo }) => {
  const videoRefs = useRef([]);

  const { fullscreen, currentCategory, setCurrentVideo, currentVideo } =
    useAppState();

  const initSwiper = (s) => {
    s.slideTo(currentVideo);
    setSwiper(s);
  };

  const defaultAsPaused = useCallback(
    (x) => {
      fullscreen
        ? console.log("fullscreen callback, is fullscreen")
        : console.log("fullscreen callback, not fullscreen");
      if (!fullscreen && videoRefs.current[x]) {
        videoRefs.current[x].currentTime = 0;
        videoRefs.current[x].pause();
      }
    },
    [fullscreen, videoRefs]
  );

  const onVideoEnd = useCallback(
    (x) => {
      trackEvent("video_end");
      videoRefs.current[x].currentTime = 0;
      videoRefs.current[x].pause();
      if (x === currentVideo) nextVideo();
    },
    [currentVideo, videoRefs, nextVideo]
  );

  useEffect(() => {
    async function effect() {
      console.log("video swiper effect fullscreen:", fullscreen);
      if (fullscreen) {
        console.log("video swiper playing current video", currentVideo);
        setTimeout(() => {
          async function play() {
            try {
              await videoRefs.current[currentVideo]?.play();
              console.log("video played", currentVideo);
              trackEvent("video_start");
            } catch (error) {
              console.log("play error", error);
              setTimeout(() => {
                async function play2() {
                  await videoRefs.current[currentVideo]?.play();
                  console.log("video played 2", currentVideo);
                }
                play2();
              });
            }
          }
          play();
        }, 0);
      }
      if (!fullscreen) {
        console.log("video swiper pausing current video", currentVideo);
        videoRefs.current[currentVideo]?.pause();
        if (videoRefs.current[currentVideo])
          videoRefs.current[currentVideo].currentTime = 0;
      }
      videoRefs.current.forEach((ref) => {
        ref.setAttribute("playsinline", "");
      });
    }
    effect();
  }, [videoRefs, currentVideo, fullscreen]);

  useEffect(() => {
    if (swiper?.el) {
      swiper.slideTo(currentVideo);
    }
  }, [swiper, currentCategory, currentVideo]);

  return (
    <Swiper
      onSwiper={initSwiper}
      className="w-full h-full"
      slidesPerView={1}
      direction="vertical"
      onSlideChange={(event) => {
        setCurrentVideo(event.activeIndex);
        console.log("oncSslideChange event", event);
        if (
          !isNaN(event.previousIndex) &&
          event.previousIndex > -1 &&
          videoRefs.current[event.previousIndex]
        ) {
          videoRefs.current[event.previousIndex].currentTime = 0;
          console.log("onSslideChange pausing...", event.previousIndex);
          videoRefs.current[event.previousIndex].pause();
        }
      }}
    >
      {currentCategory.videos.map((video, x) => (
        <SwiperSlide
          key={`fs_${video.playbackId}_${x}`}
          className="flex justify-center items-center"
        >
          <Video
            ref={(el) => (videoRefs.current[x] = el)}
            key={`${currentCategory.id}_${video.playbackId}_${x}`}
            playbackId={video.playbackId}
            location={video.location}
            // autoPlay={true}
            onPlay={() => defaultAsPaused(x)}
            onEnded={() => onVideoEnd(x)}
            isFeatured={false}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default VideoSwiper;
