import React from 'react';
import { useAppState } from '../../hooks/use-appstate';
import { cn } from '../../utils';

const Dots = () => {

    const { currentCategory, currentVideo } = useAppState();

    return (
        <div className='absolute flex flex-col space-y-2 right-3 justify-center h-full z-10'>
            {currentCategory.videos.map((video, x) => (
                <div key={`${currentCategory.id}_${video.playbackId}_dot_${x}`} className={currentVideo === x ? "selected" : ""}>
                    <div 
                        className={cn(
                            "rounded-full w-2.5 h-2.5 bg-white transition-all duration-500",
                            currentVideo === x ? "h-10 bg-ej-dark" : ""
                        )}
                    />
                </div>
            ))}
        </div>
    );
};

export default Dots;