import React from "react";
import { cn } from "../utils";

const Header = ({ className, children }) => {
  return (
    <h1
      className={cn(
        "ejheadlinefont text-4xl lg:text-3xl font-bold uppercase text-ej-dark leading-[0.8] fontsize1",
        className
      )}
    >
      {children}
    </h1>
  );
};

export default Header;
