import React, { useCallback, useEffect, useState } from "react";
import { useAppState } from "../../hooks/use-appstate";
import { AnimatePresence, motion } from "framer-motion";
import PlayButton from "./play-button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Dots from "./dots";
import EnterNow from "../enter-now";
import BookNow from "../book-now";
import { config } from "../../config";

const ThumbnailSwiper = () => {
  const {
    currentCategory,
    currentVideo,
    setCurrentVideo,
    fullscreen,
    setFullscreen,
    setIsFeatured,
  } = useAppState();

  const [swiper, setSwiper] = useState(null);

  const play = useCallback(() => {
    setFullscreen(currentCategory.videos[currentVideo].playbackId);
    setIsFeatured(false);
  }, [currentVideo]);

  useEffect(() => {
    if (fullscreen) swiper?.slideTo?.(currentVideo);
  }, [swiper, fullscreen, currentVideo]);

  if (!currentCategory) return null;

  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          key={currentCategory.id}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="relative h-full w-full pb-5"
        >
          <svg className="absolute left-5 -top-5 z-[6]">
            <path
              id="banner"
              d="M0.339362 35.7436C0.339358 31.7071 3.34653 28.3028 7.35226 27.8048L227.529 0.42847C232.302 -0.165011 236.516 3.55745 236.516 8.36734V91.9272C236.516 96.3455 232.935 99.9272 228.516 99.9272H8.33944C3.92116 99.9272 0.339419 96.3455 0.339415 91.9272L0.339362 35.7436Z"
              fill="url(#paint0_linear_0_56)"
            />
            <foreignObject className="h-20 w-full" path="banner">
              <div className="absolute flex justify-start items-center z-[2] w-full">
                <h2 className="absolute left-5 top-11 text-white text-3xl font-bold nowhitespace ejheadlinefontsmall font-size3x1">
                  {currentCategory.title}
                </h2>
              </div>
            </foreignObject>
            <defs>
              <linearGradient
                id="paint0_linear_0_56"
                x1="118.428"
                y1="-0.688965"
                x2="118.428"
                y2="105.662"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF9900" />
                <stop offset="1" stopColor="#FF4600" />
              </linearGradient>
            </defs>
          </svg>
          <div className="relative w-full h-full pt-10">
            <PlayButton play={play} />
            <Dots />
            <Swiper
              onSwiper={setSwiper}
              className="w-full h-full rounded-xl"
              slidesPerView={1}
              direction="vertical"
              onSlideChange={(event) => {
                setCurrentVideo(event.activeIndex);
              }}
            >
              {currentCategory.videos.map((video, x) => (
                <SwiperSlide
                  key={`slide_${video.playbackId}_${x}`}
                  className="flex justify-center"
                >
                  <div className="absolute inset-0 bg-black/40 z-[4]" />
                  <div
                    className="w-full h-full"
                    style={{
                      backgroundImage: `url(https://image.mux.com/${video.playbackId}/thumbnail.png?time=${video.thumbnailTime})`,
                      backgroundSize: "cover",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="absolute bottom-0 left-0 right-0 p-4 z-[5]">
              <div className="flex flex-col space-y-2">
                <EnterNow className="h-14" />
                <BookNow className="h-14" />
              </div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default ThumbnailSwiper;
