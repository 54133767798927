import React from "react";
import { motion } from "framer-motion";
import { cn } from "../utils";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../hooks/use-appstate";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const MenuItem = ({ active, item }) => {
  const { toggleMenuOpen } = useAppState();

  const navigate = useNavigate();

  const changeCategory = () => {
    navigate(`/videos/${item.id}`);
    toggleMenuOpen();
  };

  return (
    <motion.li
      className="w-full"
      variants={variants}
      whileTap={{ scale: 0.95 }}
    >
      <button
        onClick={changeCategory}
        className={cn(
          "relative overflow-hidden w-full h-24 flex rounded-lg justify-start items-end space-x-2 bg-center bg-cover text-white",
          active ? "border-1 border-ej-dark" : "",
          item.className
        )}
        style={{ backgroundImage: item.bg ? `url(${item.bg})` : "none" }}
      >
        {!item.className && (
          <div className="absolute w-full h-full bg-black/40" />
        )}
        <p className="font-bold text-xl text-white mb-2 pl-2 flex flex-row space-x-1.5 z-[1] items-center">
          {item.icon}
          <span>{item.title}</span>
        </p>
      </button>
    </motion.li>
  );
};

export default MenuItem;
