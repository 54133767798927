import React from 'react';
import { motion } from 'framer-motion'

const MoveVertical = ({ start, children, ...props }) => {
    return (
        <motion.div
            initial={{ y: start }}
            animate={{ y: 0 }}
            exit={{ y: 0 }}
            transition={{ duration: 1.2 }}
            {...props}
        >
            {children}
        </motion.div>
    );
};

export default MoveVertical;