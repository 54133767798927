import React, { useEffect, useRef } from "react";
import "@dotlottie/player-component";
import { cn } from "../utils";

import { Player } from "@lottiefiles/react-lottie-player";
import screenAnimationJson from "../assets/screen-change.json";
import { useAppState } from "../hooks/use-appstate";
import { useNavigate } from "react-router-dom";

const ScreenChange = () => {
  const playerRef = useRef();

  const navigate = useNavigate();

  const { startScreenChange, setStartScreenChange, screenChangeCallback } =
    useAppState();

  useEffect(() => {
    if (startScreenChange && screenChangeCallback && playerRef) {
      playerRef.current.play();
      const timeout = setTimeout(() => {
        navigate(screenChangeCallback);
      }, 1150);

      return () => clearTimeout(timeout);
    }
  }, [startScreenChange, screenChangeCallback]);

  return (
    <div
      className={cn(
        "absolute z-[1000] w-0",
        startScreenChange && "inset-0 w-full h-full"
      )}
    >
      <Player
        ref={playerRef}
        src={screenAnimationJson}
        onEvent={(event) => {
          if (event === "complete") {
            setStartScreenChange(false);
          }
        }}
      ></Player>
    </div>
  );
};

export default ScreenChange;
