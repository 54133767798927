import React from "react";
import ButtonFull from "./buttonfull";
import { trackEvent } from "../analytics";

const BookNow = (props) => {
  // Wrap trackEvent call in another function
  const handleClick = () => {
    trackEvent("linkout_clicked");
  };

  return (
    <a
      onClick={handleClick} // Pass the wrapper function to onClick
      href="https://www.easyjet.com/en/holidays/spain/lanzarote?utm_source=lanzarote2024app&utm_medium=social&utm_campaign=lanzarote2024comp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ButtonFull {...props}>Book Lanzarote</ButtonFull>
    </a>
  );
};

export default BookNow;
