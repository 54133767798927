import React, { createContext, useState } from 'react';
import { useCycle } from 'framer-motion';
import { config } from '../config';

export const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {

    const [onboarded, setOnboarded] = useState(false);
    const [startScreenChange, setStartScreenChange] = useState(false);
    const [screenChangeCallback, setScreenChangeCallback] = useState(null);
    const [currentCategory, setCurrentCategory] = useState(config.categories[0])
    const [currentVideo, setCurrentVideo] = useState(0);
    const [menuOpen, toggleMenuOpen] = useCycle(false, true);
    const [fullscreen, setFullscreen] = useState(null);
    const [isFeatured, setIsFeatured] = useState(false)
    const [iframeOpen, setIframeOpen] = useState(false)

    return (
        <AppStateContext.Provider value={{ 
            onboarded,
            setOnboarded,
            startScreenChange,
            setStartScreenChange,
            currentCategory,
            setCurrentCategory,
            currentVideo,
            setCurrentVideo,
            menuOpen,
            toggleMenuOpen,
            fullscreen,
            setFullscreen,
            screenChangeCallback,
            setScreenChangeCallback,
            iframeOpen,
            setIframeOpen,
            isFeatured,
            setIsFeatured
        }}>
            {children}
        </AppStateContext.Provider>
    );
};