import * as React from "react";
import { motion } from "framer-motion";
import MenuItem from "../menu-item";

import logo from "../../assets/images/logofix.png";
import { useAppState } from "../../hooks/use-appstate";
import { config } from "../../config";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "../../analytics";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const subvariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const Menu = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    trackEvent("linkout_clicked");
  };

  const {
    currentCategory,
    toggleMenuOpen,
    setIframeOpen,
    fullscreen,
    setFullscreen,
  } = useAppState();

  const openModal = () => {
    setIframeOpen(true);
    if (fullscreen) {
      setFullscreen(null);
    }
  };
  const goHome = () => {
    toggleMenuOpen();
    navigate("/home");
  };

  return (
    <div className="flex flex-col h-full overflow-y-visible">
      <div className="flex items-center margin15pxbot">
        <button onClick={goHome}>
          <img src={logo} className="quick5rem" alt="easyJet Holidays Logo" />
        </button>
      </div>
      <div className="flex flex-col h-full">
        <motion.ul
          variants={variants}
          className="flex flex-col justify-evenly w-full space-y-3"
        >
          {config.categories.map((item) => (
            <MenuItem
              item={item}
              key={`menu_${item.id}`}
              active={currentCategory?.id === item.id}
            />
          ))}
        </motion.ul>
        <motion.ul
          variants={variants}
          className="flex flex-col justify-evenly w-full space-y-3 py-3"
        >
          <motion.li variants={subvariants} whileTap={{ scale: 0.95 }}>
            <button
              onClick={() => openModal()}
              className="relative overflow-hidden w-full h-14 flex rounded-lg justify-center items-center space-x-2 bg-orange-gradient text-white"
            >
              <p className="font-bold text-lg text-white flex flex-row space-x-1.5 z-[1] items-center">
                <span className="mb-0.5">Enter now</span>
              </p>
            </button>
          </motion.li>
          <motion.li variants={subvariants} whileTap={{ scale: 0.95 }}>
            <a
              onClick={handleClick}
              target="_blank"
              rel="noreferrer"
              href="https://www.easyjet.com/en/holidays/spain/lanzarote?utm_source=lanzarote2024app&utm_medium=social&utm_campaign=lanzarote2024comp"
            >
              <button className="relative overflow-hidden w-full h-14 flex rounded-lg justify-center items-center space-x-2 bg-orange-gradient text-white">
                <p className="font-bold text-lg text-white flex flex-row space-x-1.5 z-[1] items-center">
                  <span className="mb-0.5">Book Lanzarote</span>
                </p>
              </button>
            </a>
          </motion.li>
          <motion.li variants={subvariants} whileTap={{ scale: 0.95 }}>
            <a
              onClick={handleClick}
              target="_blank"
              rel="noreferrer"
              href="https://www.easyjet.com/en/holidays/spain/lanzarote/playa-blanca/elba-lanzarote-royal-village-resort?utm_source=lanzarote2024app&utm_medium=social&utm_campaign=lanzarote2024comp"
            >
              <button
                onClick={console.log}
                className="pad-5 relative overflow-hidden w-full h-14 flex rounded-lg justify-center items-center space-x-2 bg-[#DADADA] text-white"
              >
                <p className="font-bold text-md text-[#5E5E5E] flex flex-row space-x-1.5 z-[1] items-center">
                  <span className="mb-0.5">More about the resort</span>
                </p>
              </button>
            </a>
          </motion.li>
        </motion.ul>
      </div>
    </div>
  );
};
