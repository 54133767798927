import { useCallback, useEffect, useState } from "react";

export const useDimensions = ref => {

    const [dimensions, setDimensions] = useState({width: 0, height:0, offsetX: 0});

    const getDimensions = useCallback(() => {
        return {
            width: ref?.current?.clientWidth,
            height: ref?.current?.clientHeight,
            offsetX: ref?.current?.getBoundingClientRect().left
        };
    }, [ref])

    useEffect(() => {
        setDimensions(getDimensions());
    }, [ref]);

    return dimensions;
};
