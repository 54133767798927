import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { config } from "../../config";
import { Link } from "react-router-dom";

const Footer = ({ text }) => {
  return (
    <div className="flex flex-col justify-end h-[22%] overflow-hidden p-5">
      <h3 className="text-[#414141] font-bold text-xl pl-0.5 ejheadlinefontsmall">
        {text ? text : "Explore more"}
      </h3>
      <Swiper
        className="w-full mt-1 h-28 short:h-20 tall:h-32 max-h-full"
        slidesPerView={1.8}
        direction="horizontal"
        spaceBetween={10}
        breakpoints={{
          320: {
            slidesPerView: 2.5,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
          },
        }}
      >
        {config.categories.map((category, i) => (
          <SwiperSlide
            key={`footer_${i}`}
            className="flex justify-center items-center rounded-lg"
          >
            <Link
              to={`/videos/${category.id}`}
              className="relative flex justify-start items-end bg-center bg-cover h-24 short:h-20 tall:h-32 w-36 md:w-48 rounded-lg overflow-hidden"
              style={{ backgroundImage: `url(${category.bgsq})` }}
            >
              <div className="absolute w-full h-full bg-black/40" />
              <p className="font-bold text-md text-white mb-2 pl-2 flex flex-row space-x-1.5 z-[1] items-center">
                {category.smallIcon}
                <span>{category.title}</span>
              </p>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Footer;
