import * as React from "react";
import { useAppState } from "../hooks/use-appstate";
import { cn } from "../utils";

export const MenuToggle = () => {

  const { menuOpen, toggleMenuOpen } = useAppState();
    
  return <div className={cn("menu-icon mt-2 z-[22]", menuOpen ? 'relative' : '')} onClick={toggleMenuOpen}>
    <input className="menu-icon__cheeckbox" type="checkbox" checked={menuOpen} />
		<div>
			<span></span>
			<span></span>
		</div>
	</div>
};
