import React, { useEffect } from 'react';
import Navbar from '../components/layout/navbar';
import { useParams } from "react-router-dom";
import ThumbnailSwiper from '../components/videos/thumbnail-swiper';
import { useAppState } from '../hooks/use-appstate';
import FullScreenPlayer from '../components/videos/fullscreen-player';
import { config } from '../config';

const CategoryPlayer = () => {

    const { setCurrentCategory, setCurrentVideo } = useAppState();
    const { category } = useParams();

    const videoCategory = config.categories.find(c => c.id === category);
    
    useEffect(() => {
        setCurrentCategory(videoCategory);
        setCurrentVideo(0);
    }, [videoCategory])

    return (
        <div className='relative w-full h-full overflow-hidden'>
            <Navbar />
            <div className='relative h-[88%] px-5 z-[1]'>
                <ThumbnailSwiper />
            </div>
            <FullScreenPlayer />
        </div>
    );
};

export default CategoryPlayer;