import React, { useEffect, useRef, useState } from "react";
import OnboardWrapper from "./wrapper";
import { motion, useAnimationControls, useDragControls } from "framer-motion";

import logo from "../../assets/images/logo.webp";
import Header from "../../components/header";
import MoveVertical from "../../animations/move-vertical";
import CenterBox from "../../components/center-box";
import SubHeader from "../../components/sub-header";
import { useAppState } from "../../hooks/use-appstate";
import { useNavigate } from "react-router-dom";
import plane from "../../assets/images/plane.svg";
import { cn } from "../../utils";

const dragDistance = 120;

const OnboardFirst = () => {
  const widthRef = useRef();
  const navigate = useNavigate();
  const controls = useDragControls();

  const {
    onboarded,
    setOnboarded,
    setStartScreenChange,
    setScreenChangeCallback,
  } = useAppState();

  const animateController = useAnimationControls();
  const instantController = useAnimationControls();

  const [dragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);

  function startDrag(event) {
    controls.start(event);
    setStartPosition(event.clientY);
  }

  useEffect(() => {
    setScreenChangeCallback("/home");
    if (onboarded) {
      instantController.start({
        opacity: 0,
        transition: {
          duration: 0,
        },
      });
      animateController.start({
        opacity: 0,
        transition: {
          duration: 1,
        },
      });
      setTimeout(() => {
        setStartScreenChange(true);
      }, 1000);
    }
  }, [onboarded, instantController, animateController]);

  return (
    <OnboardWrapper>
      <motion.div
        ref={widthRef}
        className="absolute inset-0"
        drag="y"
        draggable={true}
        dragElastic={1}
        initial={{ x: 0 }}
        dragConstraints={widthRef}
        dragControls={controls}
        onPointerDown={startDrag}
        style={{ touchAction: "none" }}
        onDrag={(event, info) => {
          setIsDragging(true);
          const point = info?.point?.y || event.clientY;
          if (point <= startPosition - dragDistance) {
            setOnboarded(true);
          }
        }}
        onDragEnd={() => setIsDragging(false)}
      >
        <div className="h-2/3" />
        <CenterBox className="h-1/3 justify-center items-center space-y-1">
          {!onboarded && (
            <>
              <div className="flex flex-col items-center space-y-2">
                {!dragging && (
                  <div className="arrow rotate-180">
                    <span className="block animate-arrow-up"></span>
                    <span className="block animate-arrow-up"></span>
                    <span className="block animate-arrow-up"></span>
                  </div>
                )}
                <img src={plane} className="h-10" alt="plane icon" />
              </div>
              <motion.p
                draggable={false}
                className="text-white text-lg"
                initial={{ opacity: 1 }}
                animate={{ opacity: dragging ? 0 : 1 }}
                transition={{ duration: 0.3 }}
              >
                Start Exploring
              </motion.p>
            </>
          )}
        </CenterBox>
      </motion.div>
      <div ref={widthRef} className="h-full justify-center items-center">
        <motion.div
          animate={animateController}
          className="h-1/3 overflow-hidden"
        >
          <MoveVertical
            start={-150}
            className="flex flex-col items-center justify-end h-full pb-6"
          >
            <Header className={"fontsize1"}>Discover</Header>
            <SubHeader className={"fontsize2"}>Lanzarote Your Way</SubHeader>
          </MoveVertical>
        </motion.div>
        <div className="h-1/3 overflow-hidden">
          <CenterBox>
            <MoveVertical start={150} className="flex justify-center">
              <img
                src={logo}
                className={cn("w-4/5 md:w-[400px] h-auto", dragging && "")}
                alt="easyJet Holidays Logo"
              />
            </MoveVertical>
          </CenterBox>
        </div>
        <motion.div animate={instantController} className="h-1/3">
          <CenterBox className="h-1/3 justify-center items-center space-y-1"></CenterBox>
        </motion.div>
      </div>
    </OnboardWrapper>
  );
};

export default OnboardFirst;
