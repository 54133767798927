import React from 'react';
import bg from '../../assets/images/bg.webp'

const OnboardWrapper = ({ children }) => {
    return (
        <div
            className='relative h-full w-full bg-cover bg-left bg-no-repeat z-0'
            style={{ backgroundImage: `url(${bg})` }}
        >
            <div className='absolute w-full h-full bg-black/75 z-10'/>
            <div className='relative z-20 h-full overflow-hidden'>
                {children}
            </div>
        </div>
    );
};

export default OnboardWrapper;