import React from "react";
import Navbar from "../components/layout/navbar";
import Footer from "../components/layout/footer";
import EnterNow from "../components/enter-now";
import Video from "../components/video";
import { config } from "../config";
import PlayButton from "../components/videos/play-button";
import { useAppState } from "../hooks/use-appstate";
import FullScreenPlayer from "../components/videos/fullscreen-player";

const HomeScreen = () => {
  const { setFullscreen, setIsFeatured } = useAppState();

  const loadVideo = () => {
    setFullscreen(config.heroVideo.playbackId);
    setIsFeatured(true);
  };

  return (
    <div className="relative w-full h-full overflow-hidden">
      <FullScreenPlayer />
      <Navbar />
      <div className="relative h-[8%] px-5 z-[1]">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col justify-center">
            <p className="uppercase text-black text-md leading-[1.1]">
              Win an all-inclusive <br /> Lanzarote Holiday
            </p>
          </div>
          <EnterNow />
        </div>
      </div>
      <div className="relative h-[58%] px-5 z-[1]">
        <div className="flex flex-col space-y-2 bg-ej-opaque rounded-lg h-full w-full p-4 pb-4">
          <div
            onClick={loadVideo}
            className="relative h-full rounded-lg overflow-hidden"
          >
            <Video
              hideLoader={true}
              className="rounded-lg"
              playbackId={config.heroVideo.playbackId}
              autoPlay={false}
              loop
              isFeatured={true}
            />
            <div
              className="absolute inset-0 bg-black/40 z-[4]"
              onClick={loadVideo}
            />
            <PlayButton play={loadVideo} />
          </div>
          <p className="font-bold text-[#414141] text-xl pt-1 ejheadlinefontsmall">
            {config.heroVideo.title}
          </p>
          <p className="text-[#414141] text-md">
            {config.heroVideo.description}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomeScreen;
