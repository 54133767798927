import React from 'react';

const PlayButton = ({ play }) => {
    return (
        <button onClick={play} className='center-play z-[5]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65" fill="none">
                <g clipPath="url(#clip0_0_72)">
                    <path d="M26.939 44.1789L42.689 32.1789L26.939 20.1789V44.1789ZM32.189 5.51221C17.699 5.51221 5.93896 17.4589 5.93896 32.1789C5.93896 46.8989 17.699 58.8455 32.189 58.8455C46.679 58.8455 58.439 46.8989 58.439 32.1789C58.439 17.4589 46.679 5.51221 32.189 5.51221ZM32.189 53.5122C20.6127 53.5122 11.189 43.9389 11.189 32.1789C11.189 20.4189 20.6127 10.8455 32.189 10.8455C43.7652 10.8455 53.189 20.4189 53.189 32.1789C53.189 43.9389 43.7652 53.5122 32.189 53.5122Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_0_72">
                        <rect width="63" height="64" fill="white" transform="translate(0.688965 0.178711)"/>
                    </clipPath>
                </defs>
            </svg>
        </button>
    );
};

export default PlayButton;