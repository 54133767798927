import React, { useState } from "react";
import { useEffect } from "react";
import { trackEvent } from "../analytics";
import { config } from "../config";
import CompetitionEnded from "./competition-ended";
const Form = () => {
  // State for form inputs
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isCompetitionOver, setIsCompetitionOver] = useState(false);

  // Validation function
  const validateInputs = () => {
    let errors = [];
    if (!firstName) errors.push("First Name: missing data!");
    if (!lastName) errors.push("Last Name: missing data!");
    if (!email) errors.push("Email: missing data!");

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return false;
    }
    return true;
  };

  // Form submission handler
  const handleSubmit = (e) => {
    if (!validateInputs()) {
      e.preventDefault(); // Prevent form submission if validation fails
    } else {
      trackEvent("form_submitted");
    }
  };

  useEffect(() => {
    const checkCompetitionEnd = () => {
      const currentDateTime = new Date();
      const competitionEndDateTime = new Date(config.competitionEndDate);

      if (currentDateTime >= competitionEndDateTime) {
        setIsCompetitionOver(true);
      }
    };

    checkCompetitionEnd();

    // Optional: Continuously check if the competition has ended
    const intervalId = setInterval(checkCompetitionEnd, 60000);

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  if (isCompetitionOver) {
    return <CompetitionEnded />;
  }

  return (
    <form
      name="ProfileForm"
      action="https://link.travel.easyjet.com/u/register.php"
      method="get"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="CID" value="798153599"></input>
      <input type="hidden" name="SID" value=""></input>
      <input type="hidden" name="UID" value=""></input>
      <input type="hidden" name="f" value="458"></input>
      <input type="hidden" name="p" value="2"></input>
      <input type="hidden" name="a" value="r"></input>
      <input type="hidden" name="el" value=""></input>
      <input type="hidden" name="llid" value=""></input>
      <input type="hidden" name="c" value=""></input>
      <input type="hidden" name="counted" value=""></input>
      <input type="hidden" name="RID" value=""></input>
      <input type="hidden" name="mailnow" value=""></input>
      <div className="formintro">
        Thanks for entering our competition to win an all-inclusive holiday
        worth £2,000 to the amazing Elba Lanzarote Royal Village Resort. Just
        fill in your details below to register and good luck. Competition closes
        13th August 2024. Only one entry per person allowed. For full terms{" "}
        <a
          className="bluelink"
          href="https://www.easyjet.com/en/holidays/info/social-media-competitions"
          rel="noreferrer"
          target="_blank"
        >
          {" "}
          click here.
        </a>
      </div>
      First Name:
      <input
        type="text"
        name="inp_1"
        maxLength={60}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <br />
      Last Name:
      <input
        type="text"
        name="inp_2"
        maxLength={60}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <br />
      Email:
      <input
        type="text"
        name="inp_3"
        maxLength={255}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <div className="optin">
        <input
          type="checkbox"
          name="optin"
          id="optin"
          checked={isSubscribed}
          onChange={() => setIsSubscribed(!isSubscribed)}
        />
        <label htmlFor="optin">
          I would like to receive email updates from easyJet Group Companies.
          Make sure you're the first to know about easyJet special offers and
          inspiring news on easyJet flights and holidays through email updates.
          Please see our{" "}
          <a
            className="bluelink"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.easyjet.com/en/holidays/info/prospect-privacy-policy"
          >
            privacy policy
          </a>{" "}
          for details - you may opt out at any time.
        </label>
      </div>
      <input
        aria-label="Register"
        tabIndex="993"
        type="submit"
        name="submit1"
        value="Register"
      />
    </form>
  );
};

export default Form;
