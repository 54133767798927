import React, { useCallback, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useAppState } from "../../hooks/use-appstate";
import VideoSwiper from "./video-swiper";
import Dots from "./dots";
import { useDimensions } from "../../hooks/use-dimensions";
import ScrollAnimation from "../../animations/scroll";
import EnterNow from "../enter-now";
import Video from "../video";
import { config } from "../../config";
import { useWindowDimensions } from "../../hooks/use-window-dimensions";
import BookNow from "../book-now";
import { trackEvent } from "../../analytics";

const FullScreenPlayer = ({ playbackId }) => {
  const videoRef = useRef(null);

  const {
    isFeatured,
    fullscreen,
    setFullscreen,
    currentVideo,
    setCurrentVideo,
    currentCategory,
  } = useAppState();

  const { width } = useWindowDimensions();

  const [swiper, setSwiper] = useState();

  const nextVideo = useCallback(() => {
    const videos = currentCategory?.videos;
    if (!videos || !fullscreen) return;
    if (currentVideo === videos.length - 1) {
      // last video
      setFullscreen(null);
    } else {
      swiper.slideTo(currentVideo + 1);
      setCurrentVideo(currentVideo + 1);
    }
  }, [swiper, currentVideo, currentCategory, fullscreen]);

  const reset = () => {
    setFullscreen(null);
    trackEvent("video_end");
  };

  useEffect(() => {
    console.log("playbackId", playbackId);
    if (playbackId && videoRef && fullscreen) {
      console.log("case 1");
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
      videoRef.current.play();
    } else if (isFeatured && fullscreen && videoRef) {
      console.log("case 2");
      trackEvent("video_start");
      if (videoRef.current.currentTime > 0) {
        videoRef.current.currentTime = 0;
        videoRef.current.pause();
        videoRef.current.play();
      }
    } else if (isFeatured && !fullscreen && videoRef) {
      console.log("case 3, not fullscreen so pause video");
      if (videoRef.current.currentTime > 0) {
        videoRef.current.pause();
      }
    }
  }, [playbackId, videoRef, fullscreen]);

  return (
    <AnimatePresence>
      <motion.div
        key="fs_bg"
        className="absolute inset-0 h-full z-50 bg-black"
        initial={{
          width: 0,
          opacity: 0,
        }}
        animate={{
          width: fullscreen ? width : 0,
          opacity: fullscreen ? 1 : 0,
          transition: { duration: 0.4 },
        }}
        exit={{
          width: 0,
          transition: { duration: 0.4 },
        }}
      >
        <motion.div
          className="relative w-full h-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          <button
            onClick={() => {
              setFullscreen(null);
            }}
            className="flex items-center absolute left-4 top-4 px-4 py-2 rounded-lg border border-ej-light z-[51]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 21 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.73554 15.3304L1.00629 8.60118C0.593353 8.18824 0.593353 7.51873 1.00629 7.10579L7.73554 0.376547C8.14848 -0.0363932 8.81799 -0.0363932 9.23093 0.376547C9.64387 0.789487 9.64387 1.459 9.23093 1.87194L4.30678 6.79609L19.5889 6.79609C20.1728 6.79609 20.6463 7.2695 20.6463 7.85349C20.6463 8.43747 20.1728 8.91089 19.5889 8.91089L4.30678 8.91089L9.23093 13.835C9.64387 14.248 9.64387 14.9175 9.23093 15.3304C8.81799 15.7434 8.14848 15.7434 7.73554 15.3304Z"
                fill="white"
              />
            </svg>
            <span className="text-white uppercase ml-2 font-bold text-sm">
              BACK
            </span>
          </button>
          {!isFeatured ? (
            <>
              <Dots />
              <VideoSwiper
                swiper={swiper}
                nextVideo={nextVideo}
                setSwiper={setSwiper}
              />
              {fullscreen && <ScrollAnimation />}
            </>
          ) : (
            <Video
              ref={videoRef}
              autoPlay={fullscreen !== null}
              playbackId={config.heroVideo.playbackId}
              onEnded={reset}
              isFeatured={true}
            />
          )}
          <div className="absolute bottom-0 left-0 right-0 p-4 z-[5]">
            <EnterNow className="h-14 w-full pady-10" />
            <BookNow className="h-14 w-full" />
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FullScreenPlayer;
