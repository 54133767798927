import React, { useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useAppState } from "../hooks/use-appstate";
import Form from "../components/form";

const PageWrapper = ({ children }) => {
  const pageWrapper = useRef();

  const { iframeOpen, setIframeOpen } = useAppState();

  return (
    <motion.main
      className="max-h-full bg-off-white h-full flex justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      <div
        id="page-wrapper"
        ref={pageWrapper}
        className="relative w-full h-full md:w-portrait bg-white"
      >
        {children}
        <AnimatePresence>
          {iframeOpen && (
            <>
              <motion.div
                className="absolute top-0 bottom-0 h-full w-full z-[200] flex justify-center items-center px-5"
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 100, opacity: 0 }}
              >
                <div
                  onClick={() => setIframeOpen(false)}
                  className="absolute inset-0 h-full bg-black/60"
                />
                <button
                  onClick={() => setIframeOpen(false)}
                  className="closebtn"
                >
                  X
                </button>
                <Form />
                {/*<iframe
                                className='h-2/3 w-full z-[1] rounded-lg'
                                src="https://link.travel.easyjet.com/u/register.php?CID=798153599&f=456"
                            >

                    </iframe>*/}
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </motion.main>
  );
};

export default PageWrapper;
