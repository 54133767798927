import React from "react";
import { cn } from "../utils";

const ButtonFull = ({ className, children, ...props }) => {
  return (
    <button
      className={cn(
        "bg-orange-gradient rounded-lg h-12 w-full px-3",
        className
      )}
      {...props}
    >
      <span className="text-white text-md font-bold">{children}</span>
    </button>
  );
};

export default ButtonFull;
