import React from "react";
import Button from "./button";
import { useAppState } from "../hooks/use-appstate";
import { trackEvent } from "../analytics";

const EnterNow = (props) => {
  const { setIframeOpen, fullscreen, setFullscreen } = useAppState();

  const enter = () => {
    trackEvent("form_opened");
    setIframeOpen(true);
    if (fullscreen) {
      setFullscreen(null);
    }
  };

  return (
    <Button onClick={enter} {...props}>
      Enter now
    </Button>
  );
};

export default EnterNow;
