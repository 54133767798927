import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import '@dotlottie/player-component';

import lottieFile from '../assets/swipe.lottie'

const ScrollAnimation = () => {

    const playerRef = useRef();

    const [hide, setHide] = useState(false)

    useEffect(() => {
        const hide = setTimeout(() => {
            setHide(true);
        }, 3500)

        return () => clearTimeout(hide);
    }, [])

    return (
        <AnimatePresence>
        {!hide && (
            <motion.div 
                className="absolute bottom-20 w-10 z-[5]" 
                style={{ left: 'calc(50% - 20px)'}}
                initial={{ opacity: 1 }}
                animate={{ transition: { delay: 1000 }}}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            > 
                <dotlottie-player
                    loop={2}
                    autoplay
                    ref={playerRef}
                    mode="normal"
                    src={lottieFile}
                    on
                >
                </dotlottie-player>
            </motion.div>
        )}
        </AnimatePresence>
    );
};

export default ScrollAnimation;