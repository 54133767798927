import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-0X0VBGERDS"; // Replace with your actual GA4 Measurement ID

export const initGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
  console.log("Init GA4");
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
  console.log("GA4 pageview");
};

export const trackEvent = (name) => {
  ReactGA.event(name);
  console.log("GA4 event: ", name);
};
