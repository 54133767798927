import React from 'react';
import { cn } from '../utils';

const CenterBox = ({ children, className, ...props }) => {
    return (
        <div className={cn('flex flex-col justify-start h-full', className)} {...props}>
            {children}
        </div>
    );
};

export default CenterBox;