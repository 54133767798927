import React from "react";
import { cn } from "../utils";

const SubHeader = ({ className, children }) => {
  return (
    <p
      className={cn(
        "ejheadlinefont uppercase text-white font-bold text-xl fontsize2",
        className
      )}
    >
      {children}
    </p>
  );
};

export default SubHeader;
