import React from "react";

import { config } from "../config";
import BookNow from "./book-now";

const CompetitionEnded = () => {
  const social = config.social;

  return (
    <div className="ended-popup">
      <p>
        Sorry, the competition has now closed. You can still discover all that
        Lanzarote has to offer while you're here, and make sure to follow our
        social channels to be the first to know about upcoming competitions.
      </p>
      <div className="social-links">
        {social.map((item, index) => (
          <a
            key={index}
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <img src={item.icon} alt={item.name} />
          </a>
        ))}
      </div>
      <BookNow className="h-14 w-full" />
    </div>
  );
};

export default CompetitionEnded;
