import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";

import PageWrapper from "./components/page-wrapper";
import { AppStateProvider } from "./context/app-state-provider";
import ScreenChange from "./animations/screen-change";
import ThankYou from "./screens/thankyou";

// Screens
import ErrorScreen from "./screens/error";
import Onboard from "./screens/onboard/first";
import HomeScreen from "./screens/home";
import CategoryPlayer from "./screens/category-player";

import "./App.scss";

// Analytics
import { initGA, trackPageView } from "./analytics"; // Adjust the import path if necessary

const App = () => {
  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    // Track the pageview with the path
    trackPageView(location.pathname + location.search);
  }, [location]);

  return (
    <AppStateProvider>
      <PageWrapper>
        <ScreenChange />
        <Routes>
          <Route
            path="/"
            element={<Onboard />}
            ErrorBoundary={<ErrorScreen />}
          />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/videos/:category" element={<CategoryPlayer />} />
          <Route path="thankyou" element={<ThankYou />} />
        </Routes>
      </PageWrapper>
    </AppStateProvider>
  );
};

// Wrap the App component with BrowserRouter and use a custom component to handle the location logic
const AppWrapper = () => {
  return (
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);
